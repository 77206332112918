import {
    NavigationLinkOption,
    OrchestrationNavigationAccessMode,
    OrchestrationNavigationStepStatus
} from "./orchestration-navigation.gateway";
import { Component, inject, OnDestroy } from "@angular/core";
import { Observable, retry, share, Subject, switchMap, takeUntil, timer } from "rxjs";
import { OrchestrationNavigationService } from "./orchestration-navigation.service";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { MapDataStateService } from "pages/mapdata/services/mapdata-state-service";

const TRANSLATION_NAMESPACE = "APP._Pages._MapData._OrchestrationStatus";
const INTERVAL = 60 * 1000; // one minute

@Component({
    selector: "orchestration-status",
    template: `
        @if (_options && _orNavService.orchestrationWorkflowPage; as link) {
            @if (_options.correspondingStepStatus === OrchestrationStatus.Pending) {
                <lg-icon icon="icon-info" [inline]="true" class="lg-icon--warning"></lg-icon>
                <div [innerHTML]="'.PendingChanges' | lgTranslate: { link }"></div>
            } @else if (_options.workflowAccessMode === OrchestrationAccessMode.ViewOnly) {
                <lg-icon icon="icon-info" [inline]="true" class="lg-icon--warning"></lg-icon>
                <div [innerHTML]="'.ProcessInProgress' | lgTranslate: { link }"></div>
            }
        }
    `,
    host: { class: "flexbox flexrow--center" },
    providers: [useTranslationNamespace(TRANSLATION_NAMESPACE)]
})
export class OrchestrationStatusComponent implements OnDestroy {
    protected _orNavService = inject(OrchestrationNavigationService);
    private _stateService = inject(MapDataStateService);

    protected _options: NavigationLinkOption = {} as NavigationLinkOption;
    protected OrchestrationStatus = OrchestrationNavigationStepStatus;
    protected OrchestrationAccessMode = OrchestrationNavigationAccessMode;

    private fetching$!: Observable<NavigationLinkOption>;
    private _destroyed$ = new Subject<void>();

    constructor() {
        this._orNavService.getRefreshedCurrentNavigationOption$().subscribe(options => {
            this._options = options;
            this._definePolling();
        });

        this._stateService.manualOrStatusUpdateChange$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(options => (this._options = options));
    }

    private _definePolling(): void {
        this.fetching$ = timer(5000, INTERVAL).pipe(
            switchMap(() => this._orNavService.getRefreshedCurrentNavigationOption$()),
            retry(),
            share()
        );

        this.fetching$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(options => (this._options = options));
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
