import { Injectable, inject } from "@angular/core";
import { IDrilldownBreadcrumbOption } from "@logex/framework/lg-layout";
import { AppGatewayBase } from "@shared/bases/app-gateway-base";
import { WorkflowService } from "@shared/workflow";
import { Observable, takeUntil } from "rxjs";

export interface OrchestrationNavigation {
    urls: Record<string, NavigationLink>;
    navigationalUrls: string[];
}

export interface NavigationLink {
    url: string | null;
    authorized: boolean;
    correspondingStepStatus: OrchestrationNavigationStepStatus;
    workflowAccessMode: OrchestrationNavigationAccessMode;
}

export interface NavigationLinkOption extends IDrilldownBreadcrumbOption<string>, NavigationLink {}

export enum OrchestrationNavigationStepStatus {
    None = 0,
    Pending,
    InProgress,
    Completed
}

export enum OrchestrationNavigationAccessMode {
    None = 0,
    Disabled,
    ViewOnly,
    FullAccess
}

@Injectable({ providedIn: "root" })
export class OrchestrationNavigationGateway extends AppGatewayBase {
    private _workflowService = inject(WorkflowService);
    protected _apiEndpoint!: string;

    constructor() {
        super();
        this._workflowService.currentWorkflow$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(
                workflow =>
                    (this._apiEndpoint = `clients/${workflow?.clientId}/workflows/${workflow?.id}`)
            );
    }

    getOrchestrationNavigationSteps(): Observable<OrchestrationNavigation> {
        return this._get(`${this._apiEndpoint}/navigation`);
    }
}
