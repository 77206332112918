import { Component, inject, isDevMode, OnDestroy } from "@angular/core";
import { LG_APP_INFO } from "@logex/framework/lg-application";
import { Subject } from "rxjs";
import { SidebarHelpStandardLinks } from "@logex/framework/lg-layout";
import { OrchestrationNavigationService } from "@shared/orchestration-integrations";

@Component({
    selector: "authorized-component",
    templateUrl: "./authorized.component.html"
})
export class AuthorizedComponent implements OnDestroy {
    private _orchestrationNavigationService = inject(OrchestrationNavigationService);
    private _destroyed$ = new Subject<void>();

    _devMode: boolean;
    _currentProductIcon = "icon-toolIcon-" + inject(LG_APP_INFO).productId.toLowerCase();

    _helpLinks?: SidebarHelpStandardLinks;
    _supportLink = "https://support.logex.com/nl/medicine-value-analytics/handboek/";

    // _helpLinks: SidebarHelpStandardLinks = {
    //     manual: "https://support.logex.nl/financieel-besturingsmodel/begrotingsmodel/handboek/",
    //     FAQ:
    //         "https://support.logex.nl/financieel-besturingsmodel/begrotingsmodel/veelgestelde-vragen/",
    //     dataSpecification:
    //         "https://support.logex.nl/financieel-besturingsmodel/begrotingsmodel/dataspecificaties/",
    //     webinar:
    //         "https://support.logex.nl/financieel-besturingsmodel/begrotingsmodel/webinars-presentaties/"
    // };

    constructor() {
        this._devMode = isDevMode();
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _navigateToOrchestrationWorkflowPage(): void {
        this._orchestrationNavigationService.navigateToOrchestrationWorkflowPage();
    }

    _navigateToOrchestrationLandingPage(): void {
        this._orchestrationNavigationService.navigateToOrchestrationLandingPage();
    }
}
