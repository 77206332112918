import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LgMarkSymbolsPipe, UiCoreModule } from "@logex/framework/ui-core";
import { FormsModule } from "@angular/forms";
import {
    LG_CHART_COLOR_PALETTE,
    LG_CHART_VALUE_TYPE_DICTIONARY,
    ChartValueTypeDictionary,
    DEFAULT_PALETTE,
    DEFAULT_CHART_VALUE_TYPE_DICTIONARY,
    LgChartsModule
} from "@logex/framework/lg-charts";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LgPivotTableModule } from "@logex/framework/lg-pivot-table";
import { LgLayoutModule } from "@logex/framework/lg-layout";
import { LgFlagIconComponent } from "./components/lg-flag-icon/lg-flag-icon.component";
import { LgSnoozeIconComponent } from "./components/lg-snooze-icon/lg-snooze-icon.component";
import { FormatPercentCustomPipe } from "./pipes/values/format-percent-custom.pipe";
import { FormatIntegerCustomPipe } from "./pipes/values/format-integer-custom.pipe";
import { FormatPreviewValuePipe } from "./pipes/values/format-preview-value.pipe";
import { LgUploadErrorsComponent } from "./components/lg-upload-errors/lg-upload-errors.component";
import { DisplayFormattedStringPipe } from "./pipes/display-formatted-string.pipe";
import { OrchestrationStatusComponent } from "./orchestration-integrations/orchestration-status";

export const MVA_DEFAULT_CHART_VALUE_TYPE_DICTIONARY: ChartValueTypeDictionary = {
    ...DEFAULT_CHART_VALUE_TYPE_DICTIONARY,
    custom: "#BBBBBB"
};

@NgModule({
    imports: [
        CommonModule,
        LgLayoutModule,
        LgLocalizationModule,
        LgPivotTableModule,
        UiCoreModule,
        FormsModule,
        LgChartsModule
    ],

    exports: [
        FormatPercentCustomPipe,
        FormatIntegerCustomPipe,
        FormatPreviewValuePipe,
        DisplayFormattedStringPipe,
        LgFlagIconComponent,
        LgSnoozeIconComponent,
        LgUploadErrorsComponent,
        OrchestrationStatusComponent
    ],

    providers: [
        LgMarkSymbolsPipe,
        {
            provide: LG_CHART_COLOR_PALETTE,
            useValue: DEFAULT_PALETTE
        },
        {
            provide: LG_CHART_VALUE_TYPE_DICTIONARY,
            useValue: MVA_DEFAULT_CHART_VALUE_TYPE_DICTIONARY
        },
        FormatPercentCustomPipe,
        FormatIntegerCustomPipe,
        FormatPreviewValuePipe
    ],
    declarations: [
        DisplayFormattedStringPipe,
        LgFlagIconComponent,
        LgSnoozeIconComponent,
        FormatPercentCustomPipe,
        FormatIntegerCustomPipe,
        FormatPreviewValuePipe,
        LgUploadErrorsComponent,
        OrchestrationStatusComponent
    ]
})
export class SharedModule {}
