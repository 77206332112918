import { inject, Injectable } from "@angular/core";
import {
    INodeStateStore,
    INormalizedLogexPivotDefinition,
    LogexPivotService
} from "@logex/framework/lg-pivot";
import { NavigationLinkOption } from "@shared/orchestration-integrations";
import { BehaviorSubject, filter, Observable } from "rxjs";

export interface UserSettings {
    currentView: MapDataView;
    pivotState: Record<string, INodeStateStore>;
    showFirstChangePrompt: boolean;
    TTL: number;
}

export enum MapDataView {
    columnsMapping = "columnsMapping",
    dataParsing = "dataParsing",
    rules = "rules"
}

@Injectable({ providedIn: "root" })
export class MapDataStateService {
    settings: UserSettings = {
        currentView: MapDataView.dataParsing,
        pivotState: Object.keys(MapDataView).reduce((acc: Record<string, INodeStateStore>, key) => {
            acc[key] = {};
            return acc;
        }, {}),
        showFirstChangePrompt: true,
        TTL: 20
    };

    private pivotService = inject(LogexPivotService);

    private _manualOrStatusUpdateChange$: BehaviorSubject<NavigationLinkOption | null> =
        new BehaviorSubject<NavigationLinkOption | null>(null);

    get manualOrStatusUpdateChange$(): Observable<NavigationLinkOption> {
        return this._manualOrStatusUpdateChange$.pipe(
            filter((options): options is NavigationLinkOption => options !== null)
        );
    }

    constructor() {
        // Empty
    }

    triggerManualOrStatusUpdateEvent(options: NavigationLinkOption): void {
        this._manualOrStatusUpdateChange$.next(options);
    }

    showFirstChangePrompt(): boolean {
        const result = this.settings.showFirstChangePrompt;
        this.settings.showFirstChangePrompt = false;
        return result;
    }

    savePivotState(
        pivotDefinition: INormalizedLogexPivotDefinition,
        data: unknown[],
        view: MapDataView
    ): void {
        this.pivotService.extractNodesState(
            pivotDefinition,
            data ?? [],
            ["$expanded"],
            this.settings.pivotState[view]
        );
    }

    loadPivotState(pivotDefinition: INormalizedLogexPivotDefinition, data: unknown[]): void {
        this.pivotService.applyNodesState(
            pivotDefinition,
            data,
            ["$expanded"],
            this.settings.pivotState[this.settings.currentView]
        );
    }
}
