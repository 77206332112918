import { HttpErrorResponse } from "@angular/common/http";

export default function formatServerError(
    error: HttpErrorResponse,
    defaultMessage: string
): string {
    let errorText = defaultMessage;
    if (error.error) {
        const { Message, ExceptionMessage, StackTrace, data } = error.error;
        if (Message) {
            errorText = Message;
            if (ExceptionMessage) {
                errorText += `<br /><b>${ExceptionMessage}</b>`;
            }
            if (StackTrace) {
                errorText += `<br /><div class="confirm-message__section confirm-message__scrollable"><b>Stack:</b><br />
                        ${StackTrace.replace(/\n/g, "<br/>")}</div>`;
            }
        } else if (data) {
            const find = /<b>\s*Description:\s*<\/b>([^<]*)<br>/.exec(data);
            if (find) {
                errorText = find[1];
            }
        }
    }

    return errorText;
}
